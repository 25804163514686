import { createSlice } from '@reduxjs/toolkit';
import { getDefaltFileList } from 'src/utils/utils';

const initialState = {
    random: null,
    dataModal: null,
    modalVisible: false,
    dataSearch: null,
    currentNode: null,
    deCuong: '',
    fileListDeCuong: [],
    dinhKemDeCuong: '',
    kichBan: '',
    fileListKichBan: [],
    dinhKemKichBan: '',
    fileListTacPham: [],
    dinhKemTTT: '',
    fileListTTT: [],
    dinhKemTacPham: '',
    modalAddNhanSuVisible: false,
    addNhanSuModalData: null,
    modalAddGroupVisible: false,
    addGroupModalData: null,
    tinhThanhId: '8aa0bc1d-1a42-4c5b-cace-08db7097e695', // Quảng Bình
    huyenThiId: null,
    tongHeSoKhuyenKhich: 0,
    theLoaiId: null,
    keHoachSanXuat: '',
    timeLineModalVisible: false,
    timeLineData: [],
    heSoKhaiThacDuocHuong: 100,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const chuongTrinhDeTaiSlice = createSlice({
    name: 'chuongTrinhDeTai',
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        setDataModal: (state, action) => {
            const payload = action.payload;
            state.dataModal = payload;
        },
        setTimeLineData: (state, action) => {
            const payload = action.payload;
            state.timeLineData = payload;
        },
        setModalVisible: (state, action) => {
            const payload = action.payload;
            state.modalVisible = payload;
            if (!state.modalVisible) {
                state.dataModal = null;
            }
        },
        setTimeLineModalVisible: (state, action) => {
            const payload = action.payload;
            state.timeLineModalVisible = payload;
            if (!state.timeLineModalVisible) {
                state.dataModal = null;
            }
        },
        setDataSearch: (state, action) => {
            const payload = action.payload;
            state.dataSearch = payload;
        },
        setCurrentNode: (state, action) => {
            const payload = action.payload;
            state.currentNode = payload;
        },
        resetData: (state, action) => {
            return initialState;
        },
        setRandom: (state, action) => {
            state.random = Math.random().toString(32);
        },
        setDinhKem: (state, action) => {
            const payload = action.payload;
            state.dinhKem = payload;
        },
        setFileListDeCuong: (state, action) => {
            const payload = action.payload;
            state.fileListDeCuong = payload;
        },
        setDinhKemDeCuong: (state, action) => {
            const payload = action.payload;
            state.dinhKemDeCuong = payload;
        },
        setKichBan: (state, action) => {
            const payload = action.payload;
            state.kichBan = payload;
        },
        setFileListKichBan: (state, action) => {
            const payload = action.payload;
            state.fileListKichBan = payload;
        },
        setDinhKemKichBan: (state, action) => {
            const payload = action.payload;
            state.dinhKemKichBan = payload;
        },
        setFileListTacPham: (state, action) => {
            const payload = action.payload;
            state.fileListTacPham = payload;
        },
        setDinhKemTacPham: (state, action) => {
            const payload = action.payload;
            state.dinhKemTacPham = payload;
        },
        setFileListTTT: (state, action) => {
            const payload = action.payload;
            state.fileListTTT = payload;
        },
        setDinhKemTTT: (state, action) => {
            const payload = action.payload;
            state.dinhKemTTT = payload;
        },
        setModalAddNhanSuVisible: (state, action) => {
            const payload = action.payload;
            state.modalAddNhanSuVisible = payload;
        },
        setAddNhanSuDataModal: (state, action) => {
            const payload = action.payload;
            state.addNhanSuModalData = payload;
        },
        setModalAddGroupVisible: (state, action) => {
            const payload = action.payload;
            state.modalAddGroupVisible = payload;
        },
        setAddGroupDataModal: (state, action) => {
            const payload = action.payload;
            state.addGroupModalData = payload;
        },
        setTinhThanhId: (state, action) => {
            const payload = action.payload;
            state.tinhThanhId = payload;
        },
        setHuyenThiId: (state, action) => {
            const payload = action.payload;
            state.huyenThiId = payload;
        },
        setTongHeSoKhuyenKhich: (state, action) => {
            const payload = action.payload;
            state.tongHeSoKhuyenKhich = payload;
        },
        setHeSoKhaiThacDuocHuong: (state, action) => {
            const payload = action.payload;
            state.heSoKhaiThacDuocHuong = payload;
        },
        setTheLoaiId: (state, action) => {
            const payload = action.payload;
            state.theLoaiId = payload;
        },
        setKeHoachSanXuat: (state, action) => {
            const payload = action.payload;
            state.keHoachSanXuat = payload;
        },
        setFormData: (state, action) => {
            const payload = action.payload;
            const { kichBan, fileDeCuongKichBan, fileKichBan, dinhKemTacPham, theLoaiId, tinhThanhId, huyenThiId, dinhKem } = payload ?? {};
            state.kichBan = kichBan ?? '';
            state.fileListDeCuong = fileDeCuongKichBan ? getDefaltFileList(fileDeCuongKichBan) : [];
            state.dinhKemDeCuong = fileDeCuongKichBan ?? '';
            state.fileListKichBan = fileKichBan ? getDefaltFileList(fileKichBan) : [];
            state.dinhKemKichBan = fileKichBan ?? '';
            state.fileListTacPham = dinhKemTacPham ? getDefaltFileList(dinhKemTacPham) : [];
            state.dinhKemTacPham = dinhKemTacPham ?? '';
            state.theLoaiId = theLoaiId ?? null;
            state.tinhThanhId = tinhThanhId ?? null;
            state.huyenThiId = huyenThiId ?? null;
            state.fileListTTT = dinhKem ? getDefaltFileList(dinhKem) : [];
            state.dinhKemTTT = dinhKem ?? '';
        },
    },
});
