import _ from 'lodash';
import { requestGETAttachment } from './baseAPI';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { message } from 'antd';
import { toast } from 'react-toastify';
export const API_URL = process.env.REACT_APP_API_URL;
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg =
    /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

export const getPageQuery = () => URLSearchParams(window.location.href.split('?')[1]);

export const handleDocumentAttachments = (values, URL) => {
    let res = [];
    values.forEach((i) => {
        res.push({
            ...i,
            //url: !(i?.url.includes('https://') || i?.url.includes('http://')) ? URL + i?.url : i?.url,
            path: i?.url,
            name: i?.url.substring(i?.url.lastIndexOf('/') + 1),
        });
    });
    return res;
};

export const downloadDocumentAttachment = async (fileName) => {
    try {
        const res = await requestGETAttachment(`api/v1/documentattachments/${fileName}`);
        const fileData = new Blob([res.data]);
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(fileData);
        downloadLink.download = fileName.substring(fileName.lastIndexOf('/') + 1); // Tên file và định dạng file
        downloadLink.click();
    } catch (error) {
        console.error('Lỗi khi download file:', error);
    }
};

export const handleImage = (values, URL) => {
    const arr = _.without(_.split(values, '##'), '');
    let res = [];
    arr.forEach((i) => {
        res.push({
            url: !(i.includes('https://') || i.includes('http://')) ? URL + i : i,
            path: i,
            name: i.substring(i.lastIndexOf('/') + 1),
        });
    });

    return res;
};

export const convertImage = (array) => {
    let temp = [];
    array.forEach((element) => {
        temp = _.concat(temp, element?.response?.data[0]?.url ?? element.path);
    });
    const res = _.uniq(temp).join('##');
    return res;
};

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const CheckRole = (roles, role) => {
    if (!roles) {
        return false;
    }
    return roles.some((v) => role.includes(v));
};

export const formatterInputNumber = (value) => {
    const number = Number(value);
    const formatedValue = number.toLocaleString('en-us');
    return formatedValue;
};

export const parserInputNumber = (value) => {
    if (value) {
        return parseFloat(value.replace(/,/g, ''));
    }

    return undefined;
};

export const viewSafe = async (fileName) => {
    const relativePath = `/api/v1/documentattachments/${fileName}`;
    window.open(window.location.origin + relativePath, '_blank');
};

export const getWorkingDays = (year, month) => {
    let workingDays = 0;
    let totalDays = new Date(year, month, 0).getDate();

    for (let day = 1; day <= totalDays; day++) {
        let currentDate = new Date(year, month - 1, day);
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            workingDays++;
        }
    }

    return workingDays;
};

export const getDateTimeFormat = (dateTime, format = 'DD/MM/YYYY HH:mm:ss') => moment.utc(dateTime).utcOffset(moment().utcOffset()).format(format);

export const formatDate = (date) => (date ? dayjs(refomatDate(date)) : '');

export const roleDisplay = {
    Basic: 'Cơ bản',
    '13770b14-0a29-4f7f-8235-08db9f99666a': 'Tự động 1',
    Admin: 'Quản trị',
    LanhDaoDonVi: 'Lãnh đạo đơn vị',
    ChuyenVien: 'Chuyên viên',
    PhongVien: 'Phóng viên',
    LanhDaoPhongBan: 'Lãnh đạo phòng ban',
    '093bc938-0ebd-4c8e-eef1-08db70a124b4': 'Tự động 2',
    QuanTriHeThong: 'Quản trị hệ thống',
};

export const sectionOrder = {
    'Danh mục': 1,
    'Đề tài': 2,
    'Đề cương': 3,
    'Kịch bản': 4,
    'Kế hoạch sản xuất': 5,
    'Tác phẩm': 6,
    'Nhân sự đề tài tỷ lệ': 7,
    'Quản lý thù lao nhuận bút': 8,
    Tenants: 9,
    'Hệ thống': 10,
};

export const convertNumberToVietnameseText = (inputNumber) => {
    const unitNumbers = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
    const placeValues = ['', 'nghìn', 'triệu', 'tỷ'];
    let isNegative = false;

    let sNumber = inputNumber.toString();
    let number = parseFloat(sNumber);
    if (number < 0) {
        number = -number;
        sNumber = number.toString();
        isNegative = true;
    }

    let ones, tens, hundreds;

    let positionDigit = sNumber.length;
    let result = ' ';

    if (positionDigit === 0) {
        result = unitNumbers[0] + result;
    } else {
        let placeValue = 0;

        while (positionDigit > 0) {
            tens = hundreds = -1;
            ones = parseInt(sNumber.substr(positionDigit - 1, 1));
            positionDigit--;
            if (positionDigit > 0) {
                tens = parseInt(sNumber.substr(positionDigit - 1, 1));
                positionDigit--;
                if (positionDigit > 0) {
                    hundreds = parseInt(sNumber.substr(positionDigit - 1, 1));
                    positionDigit--;
                }
            }

            if (ones > 0 || tens > 0 || hundreds > 0 || placeValue === 3) {
                result = placeValues[placeValue] + result;
            }

            placeValue++;
            if (placeValue > 3) {
                placeValue = 1;
            }

            if (ones === 1 && tens > 1) {
                result = 'một ' + result;
            } else {
                if (ones === 5 && tens > 0) {
                    result = 'lăm ' + result;
                } else if (ones > 0) {
                    result = unitNumbers[ones] + ' ' + result;
                }
            }

            if (tens < 0) {
                break;
            } else {
                if (tens === 0 && ones > 0) {
                    result = 'lẻ ' + result;
                }
                if (tens === 1) {
                    result = 'mười ' + result;
                }
                if (tens > 1) {
                    result = unitNumbers[tens] + ' mươi ' + result;
                }
            }

            if (hundreds < 0) {
                break;
            } else {
                if (hundreds > 0 || tens > 0 || ones > 0) {
                    result = unitNumbers[hundreds] + ' trăm ' + result;
                }
            }

            result = ' ' + result;
        }
    }

    result = result.trim();
    if (isNegative) {
        result = 'Âm ' + result;
    }
    return result;
};

export const checkRole = (roles, role) => {
    if (!roles) {
        return false;
    }
    return roles.some((v) => role.includes(v));
};

export const DeTaiStatus = {
    1: 'Đề tài đã tạo',
    2: 'Đề tài chờ thông qua',
    3: 'Đề tài chờ duyệt',
    31: 'Đề tài đã duyệt',
    4: 'Đề cương đã tạo',
    5: 'Đề cương chờ thông qua',
    6: 'Đề cương chờ duyệt',
    61: 'Đề cương đã duyệt',
    7: 'Kịch bản đã tạo',
    8: 'Kịch bản chờ thông qua',
    9: 'Kịch bản chờ duyệt',
    91: 'Kịch bản đã duyệt',
    10: 'Kế hoạch sản xuất đã tạo',
    11: 'Kế hoạch sản xuất chờ thông qua',
    12: 'Kế hoạch sản xuất chờ duyệt',
    121: 'Kế hoạch sản xuất đã duyệt',
    13: 'Tác phẩm được tạo',
    14: 'Tác phẩm chờ thông qua',
    15: 'Tác phẩm chờ duyệt',
    151: 'Tác phẩm được duyệt',
    16: 'Xếp loại tác phẩm được tạo',
    17: 'Xếp loại tác phẩm chờ thông qua',
    18: 'Xếp loại tác phẩm chờ duyệt',
    181: 'Xếp loại tác phẩm đã duyệt',
};

export const refomatDate = (st) => {
    if (st != null && st !== '') {
        var item = st.split('T')[0].split('-');
        var rs = item[2] + '/' + item[1] + '/' + item[0];
    }
    return rs;
};

export const removeVietnameseAccentsAndSpaces = (str) => {
    if (str !== null && str !== '' && str !== undefined) {
        return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f\s]/g, '')
            .replace(/\s+/g, '')
            .toLowerCase();
    } else {
        return null;
    }
};

export const getDefaltFileList = (file) =>
    file
        ? [
            {
                url: `${process.env.REACT_APP_API_URL}/${file}`,
                name: getFileName(file),
                uid: uuidv4(),
            },
        ]
        : [];

export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

export const getFileName = (filePath) => {
    // Kiểm tra xem đường dẫn có rỗng hoặc null không
    if (!filePath || filePath.trim() === '') {
        return '';
    }

    // Tách chuỗi theo dấu /
    var parts = filePath.split('/');
    // Lấy phần tử cuối cùng (tức là tên file)
    var fileName = parts[parts.length - 1];
    return fileName;
};

export const isFake = process.env.REACT_APP_API_URL.includes('qbtv');

export const escapeLikeChars = (input) => input.replace(/_/g, '[_]').replace(/%/g, '[%]');

export const normalizeInput = (input) => (input ? escapeLikeChars(input.trim()) : input);

export const numberPatern = /^-?\d+$/;
export const floatPatern = /^[+-]?(\d*\.)?\d+$/;

export const beforeUpload = (file) => {
    const isExe = file.type === 'application/x-msdownload' || file.name.endsWith('.exe') || file.name.endsWith('.bat');
    const maxSizeInMB = 20; // Giới hạn dung lượng file là 20MB
    const isTooLarge = file.size / 1024 / 1024 > maxSizeInMB;

    if (isExe) {
        toast.error(`Không được upload định dạng file này`);
        return false;
    }

    if (isTooLarge) {
        toast.error(`${file.name} có dung lượng lớn hơn ${maxSizeInMB}MB và không thể upload.`);
        return false;
    }

    return true;
};

export const handleError = ({ errorInfo, form }) => {
    const { errorFields } = errorInfo ?? {};
    const { messages, exception } = errorInfo?.response?.data ?? {};

    if (form && Array.isArray(errorFields) && errorFields.length > 0) {
        const firstErrorField = errorInfo.errorFields[0];
        if (firstErrorField) {
            form.scrollToField(firstErrorField.name[0]);
            const fieldInstance = form.getFieldInstance(firstErrorField.name[0]);
            if (fieldInstance && fieldInstance.focus) {
                fieldInstance.focus();
            }
        }
    } else if (Array.isArray(messages) && messages.length > 0) {
        toast.error(messages[0]);
    } else if (exception) {
        toast.error(exception);
    } else {
        toast.error('Thất bại, vui lòng thử lại! ');
    }
};

export const tyLeKhaiThacMap = {
    15: '0 - 30%',
    40: '30 - 50%',
    60: '50 - 70%',
    85: '70 - 100%',
};

export function roundNumber(value, decimals = 0) {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}

export function range(start, end, step = 1) {
    let result = [];
    if (step === 0) throw new Error('Step cannot be 0'); // tránh lặp vô tận

    if (start < end && step > 0) {
        // range tăng dần
        for (let i = start; i <= end; i += step) {
            result.push(i);
        }
    } else if (start > end && step < 0) {
        // range giảm dần
        for (let i = start; i >= end; i += step) {
            result.push(i);
        }
    }

    return result;
}
